import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { createPrivateSdk, FetchClient } from "@myparcel/sdk";
import { PostShipments } from "@myparcel/sdk";

const Data = () => {
  const { isAuthenticated, loading } = useAuth();
  const [employeeID, setEmployeeID] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  let [address3, setAddress3] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  let [country, setCountry] = useState("");
  const [workmail, setWorkmail] = useState("");
  const [privatemail, setPrivateEmail] = useState("");
  let [worknumber, setWorknumber] = useState("");
  const [UPN, setUPN] = useState("");
  const [product, setProduct] = useState("");
  const [label_description, setLabel_description] = useState("");
  const [options, setPackage_options] = useState("");
  const [dataFound, setDataFound] = useState(true);
  const [shipmentresult, setShipmentresult] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      setEmployeeID("");
    }
  }, [isAuthenticated, setEmployeeID]);

  const package_ = {
    package_type: 1,
    only_recipient: 1,
    signature: 1,
    return: 0,
    insurance: {
      amount: 50000,
      currency: "EUR",
    },
    label_description: label_description,
  };

  const mailbox_package = {
    package_type: 2,
    label_description: label_description,
  };

  const digital_stamp = {
    package_type: 4,
    label_description: label_description,
  };

  const small_package = {
    package_type: 6,
    only_recipient: 1,
    signature: 1,
    return: 0,
    insurance: {
      amount: 25000,
      currency: "EUR",
    },
    label_description: label_description,
  };

  useEffect(() => {
    if (employeeID === "") {
      setName("");
      setAddress("");
      setAddress2("");
      setAddress3("");
      setPostcode("");
      setCity("");
      setCountry("");
      setWorkmail("");
      setPrivateEmail("");
      setWorknumber("");
      setUPN("");
      setShipmentresult("");
      setLabel_description("");
      setProduct("");
    }
  }, [employeeID]);

  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      setEmployeeID("");
      setName("");
      setAddress("");
      setAddress2("");
      setAddress3("");
      setPostcode("");
      setCity("");
      setCountry("");
      setWorkmail("");
      setPrivateEmail("");
      setWorknumber("");
      setUPN("");
      setShipmentresult("");

      const apiUrl = process.env.REACT_APP_AFAS_URL;
      const filter = {
        Filters: {
          Filter: [
            {
              "@FilterId": "Filter 1",
              Field: [
                {
                  "@FieldId": "Medewerker",
                  "@OperatorType": 1,
                  "#text": employeeID,
                },
              ],
            },
          ],
        },
      };
      const authToken = process.env.REACT_APP_AFAS_TOKEN;

      const headers = {
        Authorization: `${authToken}`,
      };

      const response = await axios.get(apiUrl, {
        params: {
          filterjson: JSON.stringify(filter),
        },
        headers: headers,
      });

      const responseData = response.data.rows[0];
      // console.log(responseData);
      if (responseData) {
        // setApiData(responseData);
        setDataFound(true);
        setEmployeeID(responseData.Medewerker);
        setName(responseData.Naam);
        setAddress(responseData.Straat);
        setAddress2(JSON.stringify(responseData.Huisnummer));
        setAddress3(responseData.Toev_aan_huisnr);
        setPostcode(responseData.Postcode);
        setCity(responseData.Woonplaats);
        setCountry(responseData.Land);
        setWorkmail(responseData["Gebruiker_e-mail"]);
        setWorknumber(responseData.Mobiel_werk);
        setPrivateEmail(responseData["E-mail_prive"]);
        setUPN(responseData.UPN);
      } else {
        setEmployeeID("Niet gevonden");
        setName("");
        setAddress("");
        setAddress2("");
        setAddress3("");
        setPostcode("");
        setCity("");
        setWorkmail("");
        setWorknumber("");
        setPrivateEmail("");
        setUPN("");
        setShipmentresult("");
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    }
  };

  const countryCodes = {
    NL: "Nederland",
    BE: "België",
    D: "Duitsland",
  };

  function renderCountryCodes(country) {
    return countryCodes[country] || country;
  }

  const MY_BASE_64_ENCODED_API_KEY = process.env.REACT_APP_MYPARCEL_TOKEN;

  const clientConfig = {
    headers: {
      Authorization: "bearer " + MY_BASE_64_ENCODED_API_KEY,
    },
  };

  const sdk = createPrivateSdk(new FetchClient(clientConfig), [
    new PostShipments(),
  ]);

  if (worknumber === null) {
    worknumber = "";
  }

  if (address3 === null) {
    address3 = "";
  }

  let country_label = country;
  if (country === "D") {
    country_label = "DE";
  }

  const handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    setProduct(selectedProduct);

    switch (selectedProduct) {
      case "Startpakket":
        setPackage_options(package_);
        break;
      case "Chromebook":
        setPackage_options(small_package);
        break;
      case "Telefoon":
        setPackage_options(small_package);
        break;
      case "Simkaart":
        setPackage_options(mailbox_package);
        break;
      case "OTP Token":
        setPackage_options(digital_stamp);
        break;
      case "Oplader":
        setPackage_options(mailbox_package);
        break;
      case "Bumper":
        setPackage_options(mailbox_package);
        break;
      case "Chromebook sleeve":
        setPackage_options(mailbox_package);
        break;

      default:
        break;
    }
  };

  const shipment = {
    reference_identifier: label_description,
    recipient: {
      cc: country_label,
      postal_code: postcode,
      city: city,
      street: address,
      number: address2,
      number_suffix: address3,
      person: name,
      email: privatemail,
      phone: worknumber,
    },
    carrier: 1,
    options,
  };

  const createShipment = async () => {
    // console.log(shipment);
    try {
      const result = await sdk.postShipments({ body: [shipment] });
      console.log(result);
      setShipmentresult(result);
    } catch (error) {
      console.log(shipment);
      console.error(error);
    }
  };

  return (
    <div>
      <div className="main">
        <h3>Personeelsgegevens</h3>
        <hr></hr>
        <br></br>
        <form className="order-form" onSubmit={handleSearch}>
          <label htmlFor="employeeID">Personeelsnummer:</label>
          <input
            type="text"
            id="employeeID"
            value={employeeID}
            onChange={(e) => setEmployeeID(e.target.value)}
            autoComplete="off"
          />
          <button type="submit" onClick={handleSearch}>
            Zoeken
          </button>
          <label htmlFor="name">Naam:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="address">Straat:</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="address">Huisnummer:</label>
          <input
            type="text"
            id="address2"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="address">Toevoeging:</label>
          <input
            type="text"
            id="address3"
            value={address3 ?? ""}
            onChange={(e) => setAddress3(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="postcode">Postcode:</label>
          <input
            type="text"
            id="postcode"
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="city">Woonplaats:</label>
          <input
            type="text"
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="city">Land:</label>
          <input
            type="text"
            id="country"
            value={renderCountryCodes(country)}
            onChange={(e) => setCity(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="city">Werk e-mailadres:</label>
          <input
            type="text"
            id="workmail"
            value={workmail}
            onChange={(e) => setWorkmail(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="worknumber">Werk nummer:</label>
          <input
            type="text"
            id="worknumber"
            value={worknumber}
            onChange={(e) => setWorknumber(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <label htmlFor="UPN">UPN:</label>
          <input
            type="text"
            id="upn"
            value={UPN}
            onChange={(e) => setWorknumber(e.target.value)}
            disabled="disabled"
          />
          <div></div>
          <h3>MyParcel</h3>
          <div></div>
          <div></div>
          <label htmlFor="kenmerk">Kenmerk:</label>
          <input
            type="text"
            id="kenmerk"
            placeholder="Ticketnummer"
            value={label_description}
            onChange={(e) => setLabel_description(e.target.value)}
            autoComplete="off"
            required
            disabled={!name.trim()}
          />
          <div className="required_field">*</div>
          <label htmlFor="product">Product:</label>
          <select id="product" value={product} onChange={handleProductChange}>
            <option value="">Selecteer product</option>
            <option value="Startpakket">Startpakket</option>
            <option value="Chromebook">Chromebook</option>
            <option value="Telefoon">Telefoon</option>
            <option value="Simkaart">Simkaart</option>
            <option value="Oplader">Oplader</option>
            <option value="OTP Token">OTP Token</option>
            <option value="Bumper">Bumper</option>
            <option value="Sleeve">Chromebook sleeve</option>
          </select>
          <div className="required_field">*</div>
          <div></div>
          <button
            className="order"
            type="submit"
            onClick={createShipment}
            disabled={!label_description.trim() || !product}
          >
            MyParcel zending aanmaken
          </button>
        </form>
        <br></br>
        <hr></hr>
        <br></br>
        <div>
          Zending aangemaakt met kenmerk:{" "}
          {shipmentresult ? shipmentresult[0].reference_identifier : ""}
        </div>
        <br></br>
        <hr></hr>
        <div className="required_text">
          Bij het aanmaken van een label zijn velden met een * verplicht.
        </div>
      </div>
      ;
    </div>
  );
};

export default Data;
